import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()

export class EcosistemaService {

  private url: string = "http://localhost:8500";

  idEmpresa = '';
  idUsuario = '';
  constructor(private http: HttpClient) {

  }

  loginJazmin(cliente){

    return this.http.post<any>(`http://localhost:3500/api/login/`,cliente, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }
  authTicketapp(cliente){
    return this.http.post<any>(`${this.url}/api/login/`,cliente, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  authBodegapp(cliente){

    return this.http.post<any>(`http://localhost:8080/api/login/`,cliente, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

}
