import { Component, OnInit } from '@angular/core';
import { CategoriasService } from '../../_servicios/categorias.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss'],
})
export class CategoriaComponent implements OnInit {

  constructor(private categoriasService : CategoriasService) { }
  categoriasSubscription : Subscription;
  ngOnInit() {}
  loadCategories(event: {component: IonicSelectableComponent}) {
    event.component.showLoading();
    this.categoriasService.listar().then(servicio=>{
      servicio.subscribe(categorias => {
        event.component.items = categorias;
      });
      event.component.hideLoading();
    })
  }
  public onAddCategoria(event: {component: IonicSelectableComponent},categoriaForm) {
    console.log(event);
    categoriaForm.reset();
    event.component.showAddItemTemplate();
  }
  public onSaveCategoria(event: {component: IonicSelectableComponent,item},categoriaForm) {
    console.log(event);
    for(var elemnt in event.item){
      if(categoriaForm.controls[elemnt]){
          categoriaForm.controls[elemnt].setValue(event.item[elemnt]);
      }
    }
    event.component.showAddItemTemplate();
  }
  public searchCategorias(event: {component: IonicSelectableComponent,text: string},categoriasSubscription) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (categoriasSubscription) {
      categoriasSubscription.unsubscribe();
    }
    if (!text) {
      if (categoriasSubscription) {
        categoriasSubscription.unsubscribe();
      }
      event.component.items = [];
      event.component.endSearch();
      return;
    }
    categoriasSubscription = this.categoriasService.listar().then(servicio=>{
      servicio.subscribe(categorias => {
        if (categoriasSubscription.closed) {
          return;
        }
        event.component.items = categorias.filter(u => { return (u.nombre && u.nombre.toLowerCase().includes(text))   });
        console.log(event.component);

        event.component.endSearch();
      });
    })
  }
  public onDeleteCategoria(event: {
    component: IonicSelectableComponent,
    item
  }) {
    console.log(event);
    /*
    // Show loading while port is being added to storage.
    event.component.showLoading();

    // Delete port from storage.
    this.userService.deletePortAsync(event.item).subscribe(() => {
      // Delete port from list.
      event.component.deleteItem(event.item).then(() => {
        // Hide loading.
        event.component.hideLoading();
      });
    });
    */
  }
}
