import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {
  private url: string = "https://orca-app-3c9vq.ondigitalocean.app";
  constructor(private http:HttpClient,private login : LoginService) { }
  async listar() {

    this.url = "https://orca-app-3c9vq.ondigitalocean.app";
    return this.http.get<any[]>(`${this.url}/categoria/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
      .set('empresaId' , this.login.getEmpresa())
    });
  }
  insertar(cat){
    return this.http.post<any[]>(`${this.url}/categoria/`,cat , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
      .set('empresaId' , this.login.getEmpresa())
    });
  }
  actualizar(item,id){
    return this.http.patch<any[]>(`${this.url}/categoria/${id}`,item , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
      .set('empresaId' , this.login.getEmpresa())
    });
  }
  getCategorias(){
    return [];
  }

}
