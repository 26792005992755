import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController ,PickerController ,PopoverController ,ToastController,AlertController,ActionSheetController} from '@ionic/angular';
import { SucursalService } from '../../_servicios/sucursales.service';
import { UsuariosService } from '../../_servicios/usuarios.service';
import { UsuarioComponent } from '../usuario/usuario.component';
import { FormBuilder, Validators } from '@angular/forms';
import { IonicSelectableComponent } from 'ionic-selectable';
import { ValidationService } from '../../_servicios/validation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.page.html',
  styleUrls: ['./detalle.page.scss'],
})

export class DetalleSucursalPage implements OnInit {
  usuarios = []
  sucursalForm ;
  usuarioForm;
  flag = false;
  sucursal = {};
  @ViewChild('usuarioComponent',{static:true}) usuarioComponent : IonicSelectableComponent;

  constructor(private userCom : UsuarioComponent, private alertController:AlertController,private toastController : ToastController,private modal : ModalController,private sucursalService : SucursalService,private usuarioService:UsuariosService,private formBuilder: FormBuilder) {
    console.log(userCom);
    console.log("trate de ver el user com");

    this.sucursalForm = this.formBuilder.group({
      codigo : ['',Validators.required],
      encargado : ['',Validators.required],
      titulo : ['',Validators.required]
    })
    this.usuarioForm = this.formBuilder.group({
      correo : ['',[Validators.required,ValidationService.emailValidator]],
      nombre : ['',Validators.required],
      apellido : ['',Validators.required],
      clave : ['',Validators.required]
    })
  }
  guardar(){
    this.sucursalService.insertar(this.sucursalForm.value).subscribe(res=>{
      console.log(res);
      this.presentToast();
      this.modal.dismiss();

    })
  }
  ngOnInit() {
    this.usuarios = [];
    this.usuarioService.listar().then(servicio=>{
      servicio.subscribe(users=>{
        console.log(users);
        if(users){
          users.map(u=>{
            if(u.estado){
                this.usuarios.push(u);
            }
          })
        }
        console.log(users);
      })
    })

  }
  async presentToast() {
   const toast = await this.toastController.create({
     message: 'Sucursal creada exitosamente',
     duration: 2000
   });
   toast.present();
 }

 cerrarModal(){
   this.modal.dismiss();
 }
 async confirmar(tipo,estado,item){
   var accion = "AGREGAR"
   if(!estado){
     accion = 'ACTUALIZAR'
   }
   const alert = await this.alertController.create({
     header: 'Favor confirmar!',
     message: 'Estas a punto de <br><strong>'+accion+' UN USUARIO</strong>!!!',
     buttons: [
       {
         text: 'Cancelar',
         role: 'cancel',
         cssClass: 'secondary',
         handler: (blah) => {
           //console.log('Cancelado');
         }
       }, {
         text: 'Okay',
         handler: (accion) => {
           this.persistenciaUsuario(estado,item);
         }
       }
     ]
   });
   await alert.present();
 }
 async presentToastUser() {
   const toast = await this.toastController.create({
     message: 'Creado correctamente',
     duration: 2000
   });
   toast.present();
 }

 persistenciaUsuario(estado,item){
   if(estado){
     this.usuarioService.insertar(this.usuarioForm.value).subscribe(d=>{
       this.presentToastUser();
       this.usuarioComponent.search(this.usuarioForm.value.nombre);
       this.usuarioComponent.hideAddItemTemplate();
     });
   }else{
     this.usuarioService.actualizar(this.usuarioForm.value,item.id).subscribe(d=>{
       this.presentToastUser();
       this.usuarioComponent.search(this.usuarioForm.value.nombre);
       this.usuarioComponent.hideAddItemTemplate();
     })
   }
 }

}
