import { Component, OnInit } from '@angular/core';
import { ModalController,NavParams, AlertController } from '@ionic/angular';
import { ProductoPage } from '../producto/producto.page';
import { StockService } from '../../_servicios/stock.service';
declare function createPopper() : any;

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.page.html',
  styleUrls: ['./detalle.page.scss'],
})
export class DetallePage implements OnInit {
  unidades = 0;
  costos = 0;
  codigo = "";
  productos = [];
  banderaProducto = false;

  constructor(private stockService : StockService,private alertController : AlertController,private modalController: ModalController,private navParams : NavParams) {
    var productos = navParams.get('producto');
    this.productos = productos;

    this.banderaProducto = navParams.get('bandera');
    console.log('bandera',this.banderaProducto);
  }
  ionViewDidEnter(){
    if(this.banderaProducto){
      createPopper();
    }
  }
  getCosto(array){
    var costos = 0;
    array.map(p=>{costos+=(p.costo*p.cantidad)})

    return costos;
  }
  ngOnInit() {

    var self = this;
    this.costos = 0;
    this.unidades = 0;
    console.log(this.productos);

    this.productos.map((producto)=>{
      if(producto.estado){
        self.unidades += parseInt(producto.cantidad);
        self.costos += parseInt(producto.costo);
        self.codigo = producto.codigo;
      }
      if(!producto.estado){
        self.unidades +=  parseInt(producto.cantidad);
        self.costos +=  parseInt(producto.costo);
        self.codigo = producto.codigo;
      }
    })



  }
  async eliminar(prod){
    var self = this;
    const alert = await this.alertController.create({
      header: '¿Estas seguro que quieres eliminar?',

      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'largeAlert ',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Eliminar',
          cssClass : 'largeAlert',
          handler: (data) => {
            self.stockService.eliminar(prod,prod.id).subscribe(res=>{
              console.log(res);
              prod.estado = false;
              this.ngOnInit();
            })
          }
        }
      ]
    });
    await alert.present();
  }
  dismiss(){
    this.modalController.dismiss();
  }
  async agregar() {
    var self = this;
    const modal = await this.modalController.create({
      component: ProductoPage,
      componentProps : {
        producto : self.codigo
      }
    });
    var onDismiss = modal.onWillDismiss();
    onDismiss.then(m=>{
      if(m.data){
          console.log(m.data);
          self.productos.push(m.data)
          self.ngOnInit();
      }
    })
    return await modal.present();
  }
  async modificar(producto,indice){
    var self = this;
    const modal = await this.modalController.create({
      component: ProductoPage,
      componentProps : {
        updateProducto :  producto
      }
    });
    var onDismiss = modal.onWillDismiss();
    onDismiss.then(m=>{
      if(m.data){
          console.log(m.data);
          this.productos[indice] = m.data;
          producto = m.data;
//          self.productos.push(m.data)
          self.ngOnInit();          
      }
    })
    return await modal.present();
  }
  async cambiarValor() {
      var self = this;
      const alert = await this.alertController.create({
        header: 'Escoje!',
        inputs: [
          {
            name: 'valor',
            type: 'number',
            label: 'Valor para todos los productos',
            placeholder: 'Valor a seleccionar'
          }
        ],
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'largeAlert ',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Actualizar',
            cssClass : 'largeAlert',
            handler: (data) => {
              if(data && data['valor']){
                var precio = data['valor'];
                self.productos.map(function(produc){
                  produc['venta'] = parseInt(precio);
                  self.stockService.actualizar(produc,produc['id']).subscribe(res=>{
                      console.log(res);
                  });
                  /*.subscribe(data=>{
                    console.log(data);
                  })*/
                })
                console.log(data['valor']);

              }else{
                console.log("no puede ser vacio");

              }
            }
          }
        ]
      });
      await alert.present();
    }

    fechaToString(producto){
      var texto = new Date(producto.updatedAt).toLocaleDateString();
      return texto;
    }
}
