import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {ReactiveFormsModule,FormsModule} from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
//pages
import { ProductoPage } from './stock/producto/producto.page';
import { DetallePage } from './stock/detalle/detalle.page';
import { DetalleSucursalPage } from './sucursales/detalle/detalle.page';
import { EgresoPage } from './stock/egreso/egreso.page';
//componentes
import { ControlMessageComponent } from './control-message/control-message.component';
import { CategoriaComponent } from './stock/categoria/categoria.component';
import { UsuarioComponent } from './sucursales/usuario/usuario.component'
// servicios
import { AuthService } from './_servicios/auth.service';
import { LoginService } from './_servicios/login.service';
import { EcosistemaService } from './_servicios/ecosistema.service';
import { SucursalService } from './_servicios/sucursales.service';
import { ValidationService } from './_servicios/validation.service';
import { StockService } from './_servicios/stock.service';
import { CategoriasService } from './_servicios/categorias.service';
import { UsuariosService } from './_servicios/usuarios.service';
//imports externos
import { IonicSelectableModule } from 'ionic-selectable';

//
import { IonicStorageModule } from '@ionic/storage';


@NgModule({
  declarations: [AppComponent,ProductoPage,EgresoPage,DetalleSucursalPage,DetallePage],
  entryComponents: [ProductoPage,EgresoPage,DetalleSucursalPage,DetallePage],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonicSelectableModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    UsuarioComponent,
    CategoriaComponent,
    AuthService,
    LoginService,
    SucursalService,
    ValidationService,
    EcosistemaService,
    StockService,
    UsuariosService,
    CategoriasService,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule {}
