import { Component,OnInit, ViewChild } from '@angular/core';
import { NavParams,ModalController , AlertController ,ToastController} from '@ionic/angular';
import { StockService } from '../../_servicios/stock.service';
import { CategoriasService } from '../../_servicios/categorias.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FormBuilder, Validators } from '@angular/forms';
import { CategoriaComponent } from '../categoria/categoria.component';
import { ValidationService } from '../../_servicios/validation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.page.html',
  styleUrls: ['./producto.page.scss'],
})
export class ProductoPage implements OnInit {
  desabilitado = false;
  categorias = [];
  categoriaForm;
  idSucursal = "";
  isUpdating : boolean = false;
  idProducto = '';
  productForm ;
  categoriasSubscription : Subscription;
  product = { };
  @ViewChild('categoriaComponent',{static:true}) categoriaComponent : IonicSelectableComponent
  constructor(private toastController : ToastController,private alertController:AlertController,private category : CategoriaComponent,private formBuilder:FormBuilder,private modalController:ModalController,private navParams : NavParams,private stockService : StockService,private categoriasService:CategoriasService) {
      var codigo = navParams.get('producto');
      var suc = navParams.get('sucursal');
      const updateProducto = navParams.get('updateProducto');
      console.log('update prod',updateProducto);
      this.idSucursal = suc;
      this.categoriaForm = this.formBuilder.group({
        nombre : ['',Validators.required]
      })
      if(codigo){
        this.desabilitado = true;
      }
      this.productForm = this.formBuilder.group({
        titulo : [{value: '', disabled: this.desabilitado},Validators.required],
        sucursal : ['',Validators.required],
        categorias : [{value: '', disabled: this.desabilitado},Validators.required],
        codigo : [{value: '', disabled: this.desabilitado},Validators.required],
        cantidad : ['',Validators.required],
        url : ['',[]],
        id : ['',[]],
        costo : ['',Validators.required],
        estado : ['',[]],
        venta : ['',Validators.required]
      })
      this.productForm.controls['sucursal'].setValue(suc);


      this.categorias = this.categoriasService.getCategorias();
      console.log(codigo);
      console.log(this.categorias);
      if(updateProducto){
        this.isUpdating = true;
        this.setProduct(updateProducto)
      }
      if(codigo){

        stockService.getProducto(codigo).subscribe(d=>{
          console.log(d);
          var productos = d ;
          if(productos){
            var p = productos[0];            
            this.setProduct(p);
            this.product = p;
          }
        });

      }
  }
  setProduct(p){
    this.idProducto = p.id;
    p['sugeridoVenta'] = p.venta;
    delete p._id;
    delete p.__v;
    for(var elemnt in p){
      if(this.productForm.controls[elemnt]){
          this.productForm.controls[elemnt].setValue(p[elemnt]);
      }
    }
  }

  ngOnInit() {
  }
  async confirmar(tipo,estado,item){
    var accion = "AGREGAR"
    if(!estado){
      accion = 'ACTUALIZAR'
    }
    const alert = await this.alertController.create({
      header: 'Favor confirmar!',
      message: 'Estas a punto de <br><strong>'+accion+' UNA CATEGORIA</strong>!!!',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Cancelado');
          }
        }, {
          text: 'Okay',
          handler: (accion) => {
            this.persistenciaCategoria(estado,item);
          }
        }
      ]
    });
    await alert.present();
  }
  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Realizado correctamente',
      duration: 2000
    });
    toast.present();
  }
  dismiss(){
    this.modalController.dismiss();
  }
  persistenciaCategoria(estado,item){
    if(estado){
      this.categoriasService.insertar(this.categoriaForm.value).subscribe(d=>{
        this.presentToast();
        this.categoriaComponent.search(this.categoriaForm.value.nombre);
        this.categoriaComponent.hideAddItemTemplate();
      });
    }else{
      this.categoriasService.actualizar(this.categoriaForm.value,item.id).subscribe(d=>{
        this.presentToast();
        this.categoriaComponent.search(this.categoriaForm.value.nombre);
        this.categoriaComponent.hideAddItemTemplate();
      })
    }
  }
  guardar(){
    var arr = {};
    for(var indice in this.productForm.controls){
        arr[indice] = this.productForm.controls[indice].value;
    }
    this.stockService.insertar(arr).subscribe(d=>{
      console.log(d);
      this.modalController.dismiss(arr);
    })
  }
  actualizar(){
    var arr = {};
    for(var indice in this.productForm.controls){
        arr[indice] = this.productForm.controls[indice].value;
    }
    this.stockService.actualizar(arr,this.idProducto).subscribe(d=>{
      console.log(d);
      this.modalController.dismiss(arr);
    })
  }
  info(){
    alert("la sugerencia es un 50% mas grande que el precio de costo")
  }
}
