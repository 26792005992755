import { Component, OnInit } from '@angular/core';
import { StockService } from '../../_servicios/stock.service';
import { NavParams,ModalController , AlertController ,ToastController} from '@ionic/angular';

@Component({
  selector: 'app-egreso',
  templateUrl: './egreso.page.html',
  styleUrls: ['./egreso.page.scss'],
})
export class EgresoPage implements OnInit {
  egreso = {};
  productos = [];
  producto;
  indice = undefined;
  sucursal = "";
  cantidad : number = 0;

  constructor(private toast:ToastController,private modalController:ModalController,private stock: StockService,private navParams : NavParams,private stockService : StockService) {
      this.sucursal = navParams.get('sucursal');
  }

  ngOnInit() {
    var p = this.stockService.listarPorSucursal(this.sucursal);
    this.productos = [];
    p.then(ps=>{
      ps.subscribe(p=>{
        this.productos = p.filter( prod=>{return prod.estado});
      })
    })
  }
  async presentToast() {
   const toast = await this.toast.create({
     message: 'Egreso realizado correctamente',
     duration: 2000
   });
   toast.present();
 }
  dismiss(){
    this.modalController.dismiss();
  }
  alCambiar(){
    this.producto = this.productos[this.indice];
    console.log(this.producto);
  }
  descontar(){
    this.stock.descontar(this.cantidad,this.producto['id']).subscribe(d=>{
      console.log(d);
      if(d['error']){
        alert(d['error']);
      }else{
        this.producto.cantidad = this.producto.cantidad - d['cantidad'];
        this.presentToast();
        this.dismiss();
      }

    })
  }
}
