import { Component, OnInit } from '@angular/core';
import { UsuariosService } from '../../_servicios/usuarios.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class UsuarioComponent implements OnInit {

  constructor(private usuariosService : UsuariosService) { }
  usuariosSubscription : Subscription;
  ngOnInit() {}
  public loadUsers(event: {component: IonicSelectableComponent}) {
    event.component.showLoading();
    this.usuariosService.listar().then(servicio=>{
      servicio.subscribe(usuarios => {
        event.component.items = usuarios;
      });
      event.component.hideLoading();
    })
  }
  public onAddUsuario(event: {component: IonicSelectableComponent},usuarioForm) {
    console.log(event);
    usuarioForm.reset();
    event.component.showAddItemTemplate();
  }
  public onSaveUsuario(event: {component: IonicSelectableComponent,item},usuarioForm) {
    console.log(event);
    for(var elemnt in event.item){
      if(usuarioForm.controls[elemnt]){
          usuarioForm.controls[elemnt].setValue(event.item[elemnt]);
      }
    }
    event.component.showAddItemTemplate();
  }
  public searchusuarios(event: {component: IonicSelectableComponent,text: string},usuariosSubscription) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (usuariosSubscription) {
      usuariosSubscription.unsubscribe();
    }
    if (!text) {
      if (usuariosSubscription) {
        usuariosSubscription.unsubscribe();
      }
      event.component.items = [];
      event.component.endSearch();
      return;
    }
    usuariosSubscription = this.usuariosService.listar().then(servicio=>{
      servicio.subscribe(usuarios => {
        if (usuariosSubscription.closed) {
          return;
        }
        event.component.items = usuarios.filter(u => { return (u.nombre && u.nombre.toLowerCase().includes(text))   });
        console.log(event.component);

        event.component.endSearch();
      });
    })
  }
  public onDeleteusuario(event: {
    component: IonicSelectableComponent,
    item
  }) {
    console.log(event);
  }
}
