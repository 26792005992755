import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { LoginService } from './_servicios/login.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private loginService : LoginService,
    private router: Router ,
    private storage: Storage,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    // Or to get a key/value pair
    this.storage.get('usuarios').then((val) => {
      console.log(val);
      if(!val){        
        this.router.navigate(['/login'], {replaceUrl: true})
      }else{
        this.loginService.setToken(val['token']);
        this.loginService.setEmpresa(val['empresa']);
      }
    });
  }
}
